import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useGetContextInfoQuery } from '../../../generated/graphql';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { PrintAsQuoteWrapper } from '../../basket-purchase/print-as-quote-wrapper-component';
import Master from '../../master/master-component';
import SubscribePageWrapper from '../../user/subscribe-wrapper';
import UnsubscribePageWrapper from '../../user/unsubscribe-wrapper';
import ScrollToTop from './scroll-to-top-component';

export const StyledRouter: FunctionComponent = () => {
  const { loading, error, data } = useGetContextInfoQuery({ ssr: true });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }

  return (
    <>
      <Helmet>
        {/*HTML Lang*/}
        <html className='rs-page' />
      </Helmet>

      <Switch>
        {/* START: Routes without header/footer */}
        <Route path='(.*)/printQuote'>
          <PrintAsQuoteWrapper contextInfo={data?.master.contextInfo} />
        </Route>
        <Route path='(.*)/user/subscribe'>
          <SubscribePageWrapper />
        </Route>
        <Route path='(.*)/user/unsubscribe'>
          <UnsubscribePageWrapper />
        </Route>
        {/* END: Routes without header/footer */}

        <Route path='/'>
          <ScrollToTop />
          <Master contextInfo={data?.master.contextInfo} />
        </Route>
      </Switch>
    </>
  );
};
