import loadable from '@loadable/component';

const AddToBasketCTA = loadable(() => import(/* webpackChunkName: "shared" */ './add-to-basket-cta/add-to-basket-cta-component'), {
  resolveComponent: (components) => components.AddToBasketCTA,
});

const MetaDataAndTagging = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/metadata-tagging-component'), {
  resolveComponent: (components) => components.MetaDataAndTagging,
});

const MasterHeader = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/master-header-component'), {
  resolveComponent: (components) => components.MasterHeader,
});

const MasterBodyEnd = loadable(() => import(/* webpackChunkName: "shared" */ './metadata-tagging/master-body-end-component'), {
  resolveComponent: (components) => components.MasterBodyEnd,
});

const ConfirmationModal = loadable(
  () => import(/* webpackChunkName: "shared" */ './modals/confirmation-modal/confirmation-modal-component'),
  {
    resolveComponent: (components) => components.ConfirmationModal,
  }
);

const ProductInfoModal = loadable(
  () => import(/* webpackChunkName: "shared" */ './modals/product-info-modal/product-info-modal-component'),
  {
    resolveComponent: (components) => components.ProductInfoModal,
  }
);

const ProductInfoPill = loadable(() => import(/* webpackChunkName: "shared" */ './product-info-pill/product-info-pill-component'), {
  resolveComponent: (components) => components.ProductInfoPill,
});

const DTPStockBadge = loadable(() => import(/* webpackChunkName: "shared" */ './dtp-stock-badge/dtp-stock-badge-component'), {
  resolveComponent: (components) => components.DTPStockBadge,
});

const DTPStockInfo = loadable(() => import(/* webpackChunkName: "shared" */ './dtp-stock-info/dtp-stock-message-component'), {
  resolveComponent: (components) => components.DTPStockMessage,
});

const StockStatusCard = loadable(() => import(/* webpackChunkName: "shared" */ './stock-status-card/stock-status-card-component'), {
  resolveComponent: (components) => components.StockStatusCard,
});

export {
  AddToBasketCTA,
  DTPStockBadge,
  DTPStockInfo,
  MetaDataAndTagging,
  MasterHeader,
  MasterBodyEnd,
  ConfirmationModal,
  ProductInfoModal,
  ProductInfoPill,
  StockStatusCard,
};
