import React, { FunctionComponent, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useAccountPageQuery } from '../../../generated/graphql';
import { MyAccountNavbar } from '../../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../../rs-emd-ui-modules/src/models/master/context-info.model';
import { CurrentCustomerContext } from '../../app/current-user-context';
import { MyAccountRouter } from './my-account-router-component';
import styles from './my-account-wrapper-component.module.scss';

type MyAccountWrapperProps = {
  contextInfo: IContextInfo;
};

export const MyAccountWrapper: FunctionComponent<MyAccountWrapperProps> = (props: MyAccountWrapperProps) => {
  const { customer } = useContext(CurrentCustomerContext);
  const { pathname } = useLocation();

  const { loading, error, data } = useAccountPageQuery({
    ssr: false,
    skip: customer && !customer?.isLoggedIn,
  });

  if (customer && !customer.isLoggedIn) {
    if (pathname.toLowerCase().endsWith('forgotpassword')) return <RedirectWithStatus to={'/user/forgotPassword'} statusCode={302} />;
    else return <RedirectWithStatus to={`/user/login?redirectTo=${pathname}`} statusCode={302} />;
  }

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else
    return (
      <div>
        <MyAccountNavbar data={data}></MyAccountNavbar>
        <div className={styles.content}>
          <MyAccountRouter contextInfo={props.contextInfo}></MyAccountRouter>
        </div>
      </div>
    );
};

export default MyAccountWrapper;
