import { Observable, Subject } from 'rxjs';
import { IPartsListService } from '../rs-emd-ui-modules/src/services/parts-list/parts-list-service';
import { IAddToPartsListRequest, IAddToPartsListResult } from '../rs-emd-ui-modules/src/services/parts-list/parts-list.dto';
import {
  AddToPartsListV3Document,
  AddToPartsListV3Mutation,
  AddToPartsListV3MutationVariables,
  PartsListDocument,
  PartsListProductsDocument,
  PartsListProductsQuery,
  PartsListProductsQueryVariables,
  PartsListQuery,
  PartsListQueryVariables,
} from './../generated/graphql';
import { PartsListQueryType, PartsListRequest, PartsListResponse } from './../rs-emd-ui-modules/src/components/parts-list/parts-list.dto';
import { graphQLService } from './graphql-service';
import { headerService } from './header-service';

export class PartsListService implements IPartsListService {
  public responseObservable: Observable<PartsListResponse>;

  constructor(
    readonly requestSubject = new Subject<PartsListRequest>(),
    private readonly responseSubject = new Subject<PartsListResponse>()
  ) {
    this.responseObservable = this.responseSubject.asObservable();

    this.watchRequestSubject();
  }

  watchRequestSubject() {
    this.requestSubject.subscribe((req) => {
      switch (req.type) {
        case PartsListQueryType.GetPartsList:
          this.getPartsList(req.contextStockCode);
          return;
        case PartsListQueryType.GetPartsListProducts:
          this.getPartsListProducts(req.partsListId);
          return;
      }
    });
  }

  getPartsList(stockCode?: string) {
    graphQLService.apolloClient
      .query<PartsListQuery, PartsListQueryVariables>({
        query: PartsListDocument,
        variables: { contextStockCode: stockCode },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        result.data &&
          this.responseSubject.next({
            type: PartsListQueryType.GetPartsList,
            data: result.data.partsListPage,
          });
      });
  }

  getPartsListProducts(partsListId: number) {
    graphQLService.apolloClient
      .query<PartsListProductsQuery, PartsListProductsQueryVariables>({
        query: PartsListProductsDocument,
        variables: { partsListId: partsListId },
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        result.data &&
          this.responseSubject.next({
            type: PartsListQueryType.GetPartsListProducts,
            data: result.data.partsListPage,
          });
      });
  }

  async addToPartsList(data: IAddToPartsListRequest): Promise<IAddToPartsListResult> {
    const result = await graphQLService.apolloClient.mutate<AddToPartsListV3Mutation, AddToPartsListV3MutationVariables>({
      mutation: AddToPartsListV3Document,
      variables: data.variables,
    });

    if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    } else {
      // update header partslist totals if a new partslist was added
      if (result.data?.addToPartsListV3.isSuccess && data.variables.data.partsListName)
        headerService.requestSubject?.next({ type: 'getPartsListsTotal' });
    }

    return result;
  }
}

export const partsListService = new PartsListService();
