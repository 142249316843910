import React, { FunctionComponent } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useAccountOverviewPageQuery } from '../../../../generated/graphql';
import { MyAccountOverview } from '../../../../rs-emd-ui-modules/src/components/user';
import { RedirectWithStatus } from '../../../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';

export const MyAccountOverviewWrapper: FunctionComponent = () => {
  let location = useLocation();
  const isRedirectFromResetPassword = Boolean(JSON.parse(new URLSearchParams(location.search).get('resetPassword') ?? '0'));

  const { loading, error, data } = useAccountOverviewPageQuery({
    variables: {},
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.accountOverviewPage.isSuccess) {
    return <Redirect to='/user/login?redirectTo=/user/myaccount' />;
  } else return <MyAccountOverview data={data} isRedirectFromResetPassword={isRedirectFromResetPassword} />;
};

export default MyAccountOverviewWrapper;
