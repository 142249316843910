import { Observable, Subject } from 'rxjs';
import {
  AddToBasketV2Document,
  AddToBasketV2MutationVariables,
  ShareBasketTransactionDocument,
  ShareBasketTransactionMutation,
  ShareBasketTransactionMutationVariables,
  ShareBasketTransactionRequest,
} from '../generated/graphql';
import { IBasketService } from '../rs-emd-ui-modules/src/services/basket/basket-service';
import {
  IAddBasketServiceRequest,
  IAddBasketServiceResult,
  IBasketPageRequest,
  IBasketPageResponse,
  IBasketPageSummaryResult,
  IBulkAddToBasketRequest,
  IBulkAddToBasketResult,
  IBulkAddToBasketStatusUpdateRequest,
  IBulkAddToBasketStatusUpdateResponse,
  IClearBasketResult,
  IDeliveryOptionsRequest,
  IDeliveryOptionsResult,
  IRemoveBasketServiceRequest,
  IRemoveBasketServiceResult,
  IRemoveFromBasketRequest,
  IRemoveFromBasketResult,
  IRemovePromoCodeResult,
  ISetDeliveryMethodRequest,
  ISetDeliveryMethodResponse,
  ISetPromoCodeRequest,
  ISetPromoCodeResult,
  IShareBasketTransactionResult,
  IUpdateBasketLinesRequest,
  IUpdateBasketLinesResult,
} from '../rs-emd-ui-modules/src/services/basket/basket.dto';
import { clickEvent, pushEvent } from '../tagging/ensighten';
import { pushGA4Event } from '../tagging/ga4';
import { addToCart, removeFromCart } from '../tagging/google-analytics';
import {
  AddBasketServiceDocument,
  AddBasketServiceMutation,
  AddBasketServiceMutationVariables,
  AddToBasketV2Mutation,
  BasketPageDocument,
  BasketPageQuery,
  BasketPageQueryVariables,
  BasketPageSummaryDocument,
  BasketPageSummaryQuery,
  BasketPageSummaryQueryVariables,
  BulkAddToBasketDocument,
  BulkAddToBasketMutation,
  BulkAddToBasketMutationVariables,
  BulkAddToBasketStatusUpdateDocument,
  BulkAddToBasketStatusUpdateSubscription,
  BulkAddToBasketStatusUpdateSubscriptionVariables,
  ClearBasketDocument,
  ClearBasketMutation,
  ClearBasketMutationVariables,
  DeliveryOptionsDocument,
  DeliveryOptionsQuery,
  DeliveryOptionsQueryVariables,
  RemoveBasketServiceDocument,
  RemoveBasketServiceMutation,
  RemoveBasketServiceMutationVariables,
  RemoveFromBasketDocument,
  RemoveFromBasketMutation,
  RemoveFromBasketMutationVariables,
  RemovePromoCodeDocument,
  RemovePromoCodeMutation,
  RemovePromoCodeMutationVariables,
  SetDeliveryMethodDocument,
  SetDeliveryMethodMutation,
  SetDeliveryMethodMutationVariables,
  SetPromoCodeDocument,
  SetPromoCodeMutation,
  SetPromoCodeMutationVariables,
  UpdateLinesDocument,
  UpdateLinesMutation,
  UpdateLinesMutationVariables,
} from './../generated/graphql';
import { graphQLService } from './graphql-service';
import { headerService } from './header-service';

export class BasketService implements IBasketService {
  buttonClickEvent(info: string, stockCodes: string[]): void {
    const eventData = {
      __Type: 'buttonTracker',
      __Info: info,
      __Result: stockCodes,
    };

    return clickEvent(eventData);
  }

  brandTrackerClickEvent(info: string, brand: string): void {
    const eventData = {
      __Type: 'buttonTracker',
      __Info: info,
      __BRAND: brand,
    };

    return clickEvent(eventData);
  }

  pushEvent(eventKey: string, eventData: any): void {
    return pushEvent(eventKey, eventData);
  }

  pushGA44Event(event: any): void {
    return pushGA4Event(event);
  }

  async basketPage(req: IBasketPageRequest): Promise<IBasketPageResponse> {
    const result = await graphQLService.apolloClient.query<BasketPageQuery, BasketPageQueryVariables>({
      query: BasketPageDocument,
      variables: { asQuote: req.asQuote, summaryData: req.summaryData },
      fetchPolicy: 'no-cache',
    });

    let res: IBasketPageResponse = {};

    if (result.data) {
      res.basketPage = result.data.basketPage;

      if (req.reloadTotals)
        // update basket totals
        headerService.requestSubject?.next({ type: 'getBasketTotal' });
    }

    return res;
  }

  async addToBasket(data: { variables: AddToBasketV2MutationVariables; actionType: 'sr_page' | 'tn_page' | 'product' }) {
    const result = await graphQLService.apolloClient.mutate<AddToBasketV2Mutation, AddToBasketV2MutationVariables>({
      mutation: AddToBasketV2Document,
      variables: data.variables,
    });

    if (result.data?.addToBasketV2) {
      // update basket totals
      headerService.requestSubject?.next({ type: 'getBasketTotal' });

      result.data.addToBasketV2.tags.forEach((t) => {
        if (t.__typename === 'AddToBasketEnsighten') {
          // tagging: push add to cart event
          var products = [];

          // actionType can be:
          // "sr_page" if coming from serach results page
          // "tn_page" if coming from product list page (terminal node page)
          // "product" if coming from PDP
          // "basket: Recently viewed" if coming from basket (recently viewed area)
          products.push({
            cart_action_type: data.actionType,
            orderQuantity: t.orderQuantity,
            productId: t.productId,
          });

          pushEvent('addToCartDataBlockEvent', {
            products: products,
          });
        } else if (t.__typename === 'AddToBasketGoogleAnalytics') {
          addToCart(t);
        } else if (t.__typename === 'AddToBasketYandex') {
          var evalT = eval;
          evalT(t.script);
        } else if (t.__typename === 'AddToBasketTealium') {
          //TODO: uncomment when tealium is integrated
          // utag.link({
          //   cart_action_type: data.actionType,
          //   orderQuantity: t.orderQuantity,
          //   productId: t.productId,
          // });
        } else if (t.__typename === 'GA4Event_AddToCart') {
          pushGA4Event(t);
        }
      });
    } else if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result;
  }

  async removeFromBasket(variables: IRemoveFromBasketRequest): Promise<IRemoveFromBasketResult> {
    const result = await graphQLService.apolloClient.mutate<RemoveFromBasketMutation, RemoveFromBasketMutationVariables>({
      mutation: RemoveFromBasketDocument,
      variables: { lineIds: variables.lineIds },
    });

    if (result.data?.removeFromBasket) {
      result.data.removeFromBasket.tags.forEach((t) => {
        if (t.__typename === 'BulkRemoveFromBasketEnsighten') {
          // tagging: push basket line removed event
          t.products.forEach((p) => {
            pushEvent('basketLineRemoved', {
              articleId: p.productId,
              quantity: p.orderQuantity.toString(),
            });
          });
        } else if (t.__typename === 'BulkRemoveFromBasketGoogleAnalytics') {
          removeFromCart(t);
        } else if (t.__typename === 'BulkRemoveFromBasketTealium') {
          //TODO:implementation
        } else if (t.__typename === 'GA4Event_RemoveFromCart' || t.__typename === 'GA4Event_UpdateBasketLines') {
          pushGA4Event(t);
        }
      });
    } else if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result;
  }

  async updateLines(variables: IUpdateBasketLinesRequest): Promise<IUpdateBasketLinesResult> {
    const result = await graphQLService.apolloClient.mutate<UpdateLinesMutation, UpdateLinesMutationVariables>({
      mutation: UpdateLinesDocument,
      variables: { data: variables.data },
    });

    if (result.data?.updateLines) {
      result.data.updateLines.tags.forEach((t) => {
        if (t.__typename === 'UpdateBasketLinesEnsighten') {
          t.lines.forEach((l) => {
            // tagging: push basket line qty updated event
            if (l.oldQuantity !== l.newQuantity) {
              pushEvent('basketLineQuantityUpdated', {
                articleId: l.articleId,
                newQuantity: l.newQuantity?.toString(),
                newUnitPrice: l.newPrice,
                oldQuantity: l.oldQuantity?.toString(),
                oldUnitPrice: l.oldPrice,
              });
            }
          });
        } else if (t.__typename === 'UpdateBasketLinesGoogleAnalytics') {
          //TODO:implementation
        } else if (t.__typename === 'UpdateBasketLinesTealium') {
          //TODO:implementation
        } else if (t.__typename === 'GA4Event_UpdateBasketLines') {
          pushGA4Event(t);
        }
      });
    } else if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result;
  }

  async bulkAddToBasket(req: IBulkAddToBasketRequest): Promise<IBulkAddToBasketResult> {
    const result = await graphQLService.apolloClient.mutate<BulkAddToBasketMutation, BulkAddToBasketMutationVariables>({
      mutation: BulkAddToBasketDocument,
      variables: { data: { requestId: req.requestId, lines: req.lines } },
    });

    let res: IBulkAddToBasketResult = {};

    if (result.data?.bulkAddToBasket) {
      res.data = result.data.bulkAddToBasket;

      // tagging
      result.data.bulkAddToBasket.tags.forEach((t) => {
        if (t.__typename === 'BulkAddToBasketEnsighten') {
          // tagging: push add to cart event
          var articles: any[] = [];

          t.products.forEach((p) => {
            //exclude invalid product numbers
            // if ( --> returning null even if invalid product included
            //   result.data?.bulkAddToBasket.result.productsNotFoundSection?.lineInfo.filter((l) => l.stockCode === p.productId).length === 0
            // ) {
            articles.push({
              articleId: p.productId,
              cpnProvided: p.partNumber,
              qty: p.orderQuantity.toString(),
            });
            // }
          });

          if (articles.length > 0) {
            pushEvent(req.isAddProductsInBulk ? 'basketManualListAddition' : 'basketManualAddition', {
              articles: articles,
            });
          }
        } else if (t.__typename === 'BulkAddToBasketGoogleAnalytics') {
          addToCart(t);
        } else if (t.__typename === 'BulkAddToBasketTealium') {
          //TODO: uncomment when tealium is integrated
          // utag.link({
          //   cart_action_type: data.actionType,
          //   orderQuantity: t.orderQuantity,
          //   productId: t.productId,
          // });  }
        } else if (t.__typename === 'GA4Event_AddToCart') {
          pushGA4Event(t);
        }
      });
    } else if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }
    return res;
  }

  bulkAddToBasketStatusUpdate(req: IBulkAddToBasketStatusUpdateRequest): Observable<IBulkAddToBasketStatusUpdateResponse> {
    let responseSubject = new Subject<IBulkAddToBasketStatusUpdateResponse>();
    let responseObservable = responseSubject.asObservable();

    graphQLService.apolloClient
      .subscribe<BulkAddToBasketStatusUpdateSubscription, BulkAddToBasketStatusUpdateSubscriptionVariables>({
        query: BulkAddToBasketStatusUpdateDocument,
        variables: { requestId: req.requestId },
      })
      .subscribe({
        next(res) {
          if (res.data)
            responseSubject.next({
              message: res.data.bulkAddToBasketStatusUpdate.message,
              isError: res.data.bulkAddToBasketStatusUpdate.isError,
              isFinal: res.data.bulkAddToBasketStatusUpdate.isFinal,
              isProgress: res.data.bulkAddToBasketStatusUpdate.isProgress,
            });
        },
        error(err) {
          console.error('err', err);
        },
      });

    return responseObservable;
  }

  async clearBasket(): Promise<IClearBasketResult> {
    const result = await graphQLService.apolloClient.mutate<ClearBasketMutation, ClearBasketMutationVariables>({
      mutation: ClearBasketDocument,
    });

    if (result.data?.clearBasket) {
      // update basket totals
      headerService.requestSubject?.next({ type: 'getBasketTotal' });

      result.data.clearBasket.tags.forEach((t) => {
        if (t.__typename === 'BulkRemoveFromBasketEnsighten') {
          // tagging: push basket line removed event
          var articles: any[] = [];

          t.products.forEach((p) => {
            articles.push({
              articleId: p.productId,
              quantity: p.orderQuantity.toString(),
            });
          });

          if (articles.length > 0) {
            pushEvent('basketLineRemoved', {
              articles: articles,
            });
          }
        } else if (t.__typename === 'BulkRemoveFromBasketGoogleAnalytics') {
          removeFromCart(t);
        } else if (t.__typename === 'BulkRemoveFromBasketTealium') {
          //TODO: implementation
        } else if (t.__typename === 'GA4Event_RemoveFromCart' || t.__typename === 'GA4Event_UpdateBasketLines') {
          pushGA4Event(t);
        }
      });
    } else if (result.errors) {
      window.location.href = process.env.REACT_APP_ERROR_ENDPOINT ?? '';
    }

    return result;
  }

  async setDeliveryMethod(variables: ISetDeliveryMethodRequest): Promise<ISetDeliveryMethodResponse> {
    const result = await graphQLService.apolloClient.mutate<SetDeliveryMethodMutation, SetDeliveryMethodMutationVariables>({
      mutation: SetDeliveryMethodDocument,
      variables: { data: variables.data },
    });

    let res: ISetDeliveryMethodResponse = {};

    if (result.data) {
      res = result.data.setDeliveryMethod;
    }

    return res;
  }

  async deliveryOptions(req: IDeliveryOptionsRequest): Promise<IDeliveryOptionsResult> {
    const result = await graphQLService.apolloClient.query<DeliveryOptionsQuery, DeliveryOptionsQueryVariables>({
      query: DeliveryOptionsDocument,
      variables: { countryCode: req.countryCode },
      fetchPolicy: 'network-only',
    });

    let res: IDeliveryOptionsResult = {};

    if (result.data) {
      res.data = result.data.basketPage;
    }

    return res;
  }

  async basketPageSummary(): Promise<IBasketPageSummaryResult> {
    const result = await graphQLService.apolloClient.query<BasketPageSummaryQuery, BasketPageSummaryQueryVariables>({
      query: BasketPageSummaryDocument,
      fetchPolicy: 'no-cache',
    });

    let res: IBasketPageSummaryResult = {};

    if (result.data) {
      res.data = { summary: result.data.basketPage.summary };
    }

    return res;
  }

  async setPromoCode(variables: ISetPromoCodeRequest): Promise<ISetPromoCodeResult> {
    const result = await graphQLService.apolloClient.mutate<SetPromoCodeMutation, SetPromoCodeMutationVariables>({
      mutation: SetPromoCodeDocument,
      variables: { data: variables },
    });

    let res: ISetPromoCodeResult = {};

    if (result.data) {
      res.data = result.data.setPromoCode;

      //only push event for valid promo codes
      if (result.data.setPromoCode.isSuccess) {
        result.data.setPromoCode.tags.forEach((t) => {
          if (t.__typename === 'SetPromoCodeEnsighten') {
            pushEvent('SPA.promoCode', t);
          } else if (t.__typename === 'GA4Event_SelectPromotion') {
            pushGA4Event(t);
          }
        });
      }
    }

    return res;
  }

  async removePromoCode(): Promise<IRemovePromoCodeResult> {
    const result = await graphQLService.apolloClient.mutate<RemovePromoCodeMutation, RemovePromoCodeMutationVariables>({
      mutation: RemovePromoCodeDocument,
    });

    let res: IRemovePromoCodeResult = {};

    if (result.data) {
      res.data = result.data.removePromoCode;
    }

    return res;
  }

  async addBasketService(variables: IAddBasketServiceRequest): Promise<IAddBasketServiceResult> {
    const result = await graphQLService.apolloClient.mutate<AddBasketServiceMutation, AddBasketServiceMutationVariables>({
      mutation: AddBasketServiceDocument,
      variables: variables,
    });

    let res: IAddBasketServiceResult = {};

    if (result.data) {
      res.data = result.data.addBasketService;
    }

    return res;
  }

  async removeBasketService(variables: IRemoveBasketServiceRequest): Promise<IRemoveBasketServiceResult> {
    const result = await graphQLService.apolloClient.mutate<RemoveBasketServiceMutation, RemoveBasketServiceMutationVariables>({
      mutation: RemoveBasketServiceDocument,
      variables: variables,
    });

    let res: IRemoveBasketServiceResult = {};

    if (result.data) {
      res.data = result.data.removeBasketService;
    }

    return res;
  }

  async shareBasketTransaction(variables: ShareBasketTransactionRequest): Promise<IShareBasketTransactionResult> {
    const result = await graphQLService.apolloClient.mutate<ShareBasketTransactionMutation, ShareBasketTransactionMutationVariables>({
      mutation: ShareBasketTransactionDocument,
      variables: { input: variables },
    });

    let res: IShareBasketTransactionResult = {};

    if (result.data) {
      res.data = result.data.shareBasketTransaction;
    }

    return res;
  }
}

export const basketService = new BasketService();
