import loadable from '@loadable/component';

// Product Detail Page
const ProductDetailPage = loadable(() => import(/* webpackChunkName: "pdp" */ './product-detail-page-component'), {
  resolveComponent: (components) => components.ProductDetailPage,
});

// Image Gallery
const Magnifier = loadable(() => import(/* webpackChunkName: "pdp" */ './image-gallery/magnifier/magnifier-component'), {
  resolveComponent: (components) => components.Magnifier,
});
const MediaCarousel = loadable(() => import(/* webpackChunkName: "pdp" */ './image-gallery/media-carousel/media-carousel-component'), {
  resolveComponent: (components) => components.MediaCarousel,
});
const MediaModal = loadable(() => import(/* webpackChunkName: "pdp" */ './image-gallery/media-modal/media-modal-component'), {
  resolveComponent: (components) => components.MediaModal,
});
const PositionIndicator = loadable(
  () => import(/* webpackChunkName: "pdp" */ './image-gallery/position-indicator/position-indicator-component'),
  {
    resolveComponent: (components) => components.PositionIndicator,
  }
);

// Price Area
const PriceArea = loadable(() => import(/* webpackChunkName: "pdp" */ './price-area/price-area-component'), {
  resolveComponent: (components) => components.PriceArea,
});

// Price Break Table
const PriceBreakTable = loadable(() => import(/* webpackChunkName: "pdp" */ './price-break-table/price-break-table-component'), {
  resolveComponent: (components) => components.PriceBreakTable,
});

// Production Packaging (PPK)
const PPK = loadable(() => import(/* webpackChunkName: "pdp" */ './production-packaging/ppk-component'), {
  resolveComponent: (components) => components.PPK,
});

// Punchout Info - Restricted/Barred
const PunchoutInfo = loadable(() => import(/* webpackChunkName: "pdp" */ './punchout-info/punchout-info-component'), {
  resolveComponent: (components) => components.PunchoutInfo,
});

// Technical Documents
const TechnicalDocuments = loadable(() => import(/* webpackChunkName: "pdp" */ './techical-documents/technical-documents-component'), {
  resolveComponent: (components) => components.TechnicalDocuments,
});

export {
  ProductDetailPage,
  Magnifier,
  MediaCarousel,
  MediaModal,
  PositionIndicator,
  PriceArea,
  PriceBreakTable,
  PPK,
  PunchoutInfo,
  TechnicalDocuments,
};
