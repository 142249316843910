import React, { FunctionComponent } from 'react';
import { PageAlert } from 'rs-emd-ui-atoms';
import styles from './site-wide-banner-component.module.scss';
import { ILabel } from '../../models/common/label.model';
import { getLabel } from '../..';
import { Link } from 'react-router-dom';

type SiteWideBannerProps = {
  labels?: ILabel[] | null;
};

export const SiteWideBanner: FunctionComponent<SiteWideBannerProps> = (props: SiteWideBannerProps) => {
  const siteWideBannerBodyLabel = getLabel(props.labels, 'site_wide_banner_body');

  return (
    <div className={styles['site-wide-banner']}>
      <PageAlert icon='info-outline' colour='teal'>
        <div className={styles['site-wide-banner-content']}>
          <p className={styles['site-wide-banner-headline']}>{getLabel(props.labels, 'site_wide_banner_headline')}</p>
          {siteWideBannerBodyLabel !== 'site_wide_banner_body' && (
            <p className={styles['site-wide-banner-body']}>{siteWideBannerBodyLabel}</p>
          )}
          <Link to={getLabel(props.labels, 'site_wide_banner_link').trim()}>
            {getLabel(props.labels, 'site_wide_banner_find_out_more')}
          </Link>
        </div>
      </PageAlert>
    </div>
  );
};
