import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import React, { Fragment, useCallback, useState } from 'react';
import { BrowserRouter, StaticRouter } from 'react-router-dom';
import { window } from 'window-or-global';
import { ICustomer } from '../../rs-emd-ui-modules/src/models/master/header/header-menu.model';
import { graphQLService } from '../../services/graphql-service';
import './../../styles/index.scss';
import { CurrentCustomerContext } from './current-user-context';
import { BaseRouter } from './router/base-router.component';
import { QuickOrderContextProps, QuickOrderContext } from './quick-order-context';
import { PartsListContext, PartsListsContextProps } from './parts-list-context';

function App(props: any) {
  const { ssrReq, gqlClient, context, ...elts } = props;
  const ssrLocation = ssrReq ? ssrReq.url : null;
  const ssrHost = ssrReq?.get('host');
  const [customer, setCustomer] = useState<ICustomer | undefined>(undefined);
  const [quickOrderProps, setQuickOrderProps] = useState<QuickOrderContextProps | undefined>(undefined);
  const [partsListProps, setPartsListProps] = useState<PartsListsContextProps | undefined>(undefined);

  const setQuickOrderVisible = useCallback((visible: boolean) => {
    setQuickOrderProps(
      (props) =>
        ({
          ...props,
          isVisible: visible,
        } as QuickOrderContextProps)
    );
  }, []);

  let apolloProvider = (
    <ApolloProvider client={gqlClient ? gqlClient : graphQLService.apolloClient}>
      <QuickOrderContext.Provider value={{ quickOrderProps, setQuickOrderProps, setQuickOrderVisible }}>
        <PartsListContext.Provider value={{ partsListProps, setPartsListProps }}>
          <CurrentCustomerContext.Provider
            value={{
              customer,
              setCustomer,
            }}>
            {getRouter()}
          </CurrentCustomerContext.Provider>
        </PartsListContext.Provider>
      </QuickOrderContext.Provider>
    </ApolloProvider>
  );

  if (process.env.REACT_APP_AUTH_ENABLED === 'true')
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN ?? ''}
        clientId={process.env.REACT_APP_AUTH_CLIENTID ?? ''}
        authorizationParams={{
          redirect_uri: `${window?.location.origin ?? ssrHost}/user/login`,
          organization: process.env.REACT_APP_AUTH_ORG_MT,
        }}>
        {apolloProvider}
      </Auth0Provider>
    );
  else return apolloProvider;

  function getRouter() {
    if (ssrLocation == null) {
      return <BrowserRouter>{getChildren()}</BrowserRouter>;
    } else {
      return (
        <StaticRouter location={ssrLocation} context={context}>
          {getChildren()}
        </StaticRouter>
      );
    }
  }

  function getChildren() {
    return (
      <Fragment>
        <BaseRouter />
      </Fragment>
    );
  }
}

export default App;
