import { gql } from '@apollo/client';

export const PLPProduct = gql`
  fragment PLPProductType on PLPProductType {
    isVisible
    title
    mode
    message
  }

  fragment PLPProduct on PLPProduct {
    title {
      stockCode
      formattedStockCode
      title
      brand
      hsnCode
      mpn
      primaryImageUrl
      pdpUrl
      impaCode
    }
    categoryAttributes {
      attributeNameId
      value
    }
    priceArea {
      price {
        ...PriceData
      }
      container
      minimumOrderQuantity
    }
    productTypeArea {
      ...PLPProductType
      secondaryMessages {
        ...PLPProductType
      }
    }
    ppkArea {
      isVisible
      pdpURL
    }
    punchoutInfoArea {
      isBarred
      isRestricted
    }
    energyArea {
      energyLabel {
        rating
        imgUrl
      }
    }
  }
`;

export const PLPProducts = gql`
  fragment PLPProducts on PLPProducts {
    products {
      ...PLPProduct
    }
    categoryAttributes {
      attributes {
        name
        id
      }
    }
  }
`;

export const PLPFacets = gql`
  fragment Facets on PLPFacets {
    facets {
      ...Facet
    }
    flagFacets {
      ...FacetValue
    }
  }
`;

export const PLPCategoryItem = gql`
  fragment PLPCategoryItem on PLPCategoryItem {
    id
    noOfProducts
    name
    childCategories {
      id
      name
      noOfProducts
    }
  }
`;

export default gql`
  query productListPage($slug: String, $tagName: String, $searchQuery: String, $id: Int) {
    productListPage(slug: $slug, searchQuery: $searchQuery, tagName: $tagName, id: $id) {
      isSuccess
      redirectUrl
      categoryId
      title
      description
      metaData {
        pageTitle
        canonicalUrl
        metaTags {
          attributes {
            ...HtmlTagAttribute
          }
        }
        links {
          attributes {
            ...HtmlTagAttribute
          }
        }
      }
      tagging {
        gtm {
          dataLayer {
            innerHtml
          }
        }
        tags {
          ...HtmlTag
        }
      }
      labels {
        ...Label
      }
      breadcrumb {
        ...Link
      }
      productsArea {
        ...PLPProducts
      }
      facetsArea {
        ...Facets
        appliedFilters {
          ...Facet
        }
        appliedFlagFilters {
          ...FacetValue
        }
      }
      categoriesArea {
        ...PLPCategoryItem
      }
      summary {
        totalProducts
      }
      config {
        showYourPrice
        isLoggedIn
        queryStock
      }
    }
  }
`;

export const PreviewFilters = gql`
  query previewFilters($id: Int, $searchQuery: String, $tagName: String, $selector: SearchSelector) {
    productListPage(id: $id, searchQuery: $searchQuery, tagName: $tagName, selector: $selector) {
      facetsArea {
        ...Facets
      }
      summary {
        totalProducts
      }
    }
  }
`;

export const PreviewFiltersTotalProducts = gql`
  query previewFiltersTotalProducts($slug: String, $selector: SearchSelector) {
    productListPage(slug: $slug, selector: $selector) {
      summary {
        totalProducts
      }
    }
  }
`;

export const FilterProducts = gql`
  query filterProducts($id: Int, $searchQuery: String, $tagName: String, $first: Int, $selector: SearchSelector, $sort: SearchSort) {
    productListPage(id: $id, searchQuery: $searchQuery, tagName: $tagName, first: $first, selector: $selector, sort: $sort) {
      productsArea {
        ...PLPProducts
      }
      facetsArea {
        appliedFilters {
          ...Facet
        }
        appliedFlagFilters {
          ...FacetValue
        }
      }
      categoriesArea {
        ...PLPCategoryItem
      }
      summary {
        totalProducts
      }
    }
  }
`;

export const FilterProductsAndFacets = gql`
  query filterProductsAndFacets(
    $id: Int
    $searchQuery: String
    $tagName: String
    $first: Int
    $offset: Int
    $selector: SearchSelector
    $sort: SearchSort
  ) {
    productListPage(
      id: $id
      searchQuery: $searchQuery
      tagName: $tagName
      first: $first
      offset: $offset
      selector: $selector
      sort: $sort
    ) {
      productsArea {
        ...PLPProducts
      }
      facetsArea {
        ...Facets
        appliedFilters {
          ...Facet
        }
        appliedFlagFilters {
          ...FacetValue
        }
      }
      categoriesArea {
        ...PLPCategoryItem
      }
      summary {
        totalProducts
      }
    }
  }
`;

export const GetProducts = gql`
  query getProducts(
    $id: Int
    $searchQuery: String
    $tagName: String
    $first: Int
    $offset: Int
    $selector: SearchSelector
    $sort: SearchSort
  ) {
    productListPage(
      id: $id
      searchQuery: $searchQuery
      tagName: $tagName
      first: $first
      offset: $offset
      selector: $selector
      sort: $sort
    ) {
      productsArea {
        ...PLPProducts
      }
    }
  }
`;

export const GetPLPStock = gql`
  query getPLPStock(
    $id: Int
    $searchQuery: String
    $tagName: String
    $first: Int
    $offset: Int
    $selector: SearchSelector
    $sort: SearchSort
  ) {
    productListPage(
      id: $id
      searchQuery: $searchQuery
      tagName: $tagName
      first: $first
      offset: $offset
      selector: $selector
      sort: $sort
    ) {
      productsArea(queryStock: true) {
        products {
          title {
            stockCode
          }
          stockArea {
            statusCode
            title
            stockMessage
            dtpStockMessages
            infoMessage
            isUsingDTP
            denyBackOrder
            availabilityDate
            stockAvailability {
              quantityInStock
              availabilityDate
            }
            includesDate
          }
        }
      }
    }
  }
`;
