import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useBasketPageQuery } from '../../generated/graphql';
import { Basket } from '../../rs-emd-ui-modules/src/components/basket-purchase/basket';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { basketService } from '../../services/basket-service';
import { loginService } from '../../services/login-service';
import { partsListService } from '../../services/parts-list-service';
import { pricingService } from '../../services/pricing-service';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { productService } from '../../services/product-service';

type BasketPageWrapperProps = {
  contextInfo: IContextInfo;
};

export const BasketWrapper: FunctionComponent<BasketPageWrapperProps> = (props: BasketPageWrapperProps) => {
  let location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const isRedirectedFromInactiveCheckout = Boolean(JSON.parse(searchParams.get('inactivePurchase') ?? '0'));
  const promo = searchParams.get('promo');
  const productsQS = searchParams.get('products');

  //for mobile app to pre-set countries selected in onboarding screen
  let basedInCountryCode = searchParams.get('from');
  let deliveringToCountryCode = searchParams.get('to');
  let endCountryCode = searchParams.get('end');

  const { loading, error, data } = useBasketPageQuery({
    variables: { summaryData: { to: deliveringToCountryCode, from: basedInCountryCode } },
    ssr: true,
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.basketPage.labels || data.basketPage.labels.length === 0) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  }

  //check if country codes exist in country list
  basedInCountryCode =
    data.basketPage.deliveryCountries.options.filter((c) => c.code === basedInCountryCode).length > 0 ? basedInCountryCode : null;
  deliveringToCountryCode =
    data.basketPage.deliveryCountries.options.filter((c) => c.code === deliveringToCountryCode).length > 0 ? deliveringToCountryCode : null;
  endCountryCode = data.basketPage.deliveryCountries.options.filter((c) => c.code === endCountryCode).length > 0 ? endCountryCode : null;

  return (
    <Basket
      data={data.basketPage}
      locale={props.contextInfo.locale}
      isRedirectedFromInactiveCheckout={isRedirectedFromInactiveCheckout}
      productsQS={productsQS}
      urlSummaryData={{ to: deliveringToCountryCode, from: basedInCountryCode }}
      promo={promo ?? undefined}
      pricingService={pricingService}
      basketService={basketService}
      loginService={loginService}
      partsListService={partsListService}
      productService={productService}
    />
  );
};

export default BasketWrapper;
