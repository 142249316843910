import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'rs-emd-ui-atoms';
import { useProductListPageQuery } from '../../generated/graphql';
import { ProductListPage } from '../../rs-emd-ui-modules/src/components/product-list-page';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { basketService } from '../../services/basket-service';
import { partsListService } from '../../services/parts-list-service';
import { plpService } from '../../services/plp-service';
import { pricingService } from '../../services/pricing-service';
import { productService } from '../../services/product-service';
import { clearDigitalData } from '../../tagging/ensighten';

type PLPWrapperProps = {
  contextInfo: IContextInfo;
};

export const PLPWrapper: FunctionComponent<PLPWrapperProps> = (props: PLPWrapperProps) => {
  let location = useLocation();
  const history = useHistory();
  let slugConstant = '/browse/';

  const tagName = new URLSearchParams(location.search).get('tag');

  const { loading, error, data } = useProductListPageQuery({
    variables: { slug: location.pathname.substring(location.pathname.lastIndexOf(slugConstant) + slugConstant.length), tagName: tagName },
    ssr: true,
  });

  if (loading) {
    return <Loader />;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  } else if (!data.productListPage.isSuccess) {
    if (data.productListPage.redirectUrl)
      return (
        <RedirectWithStatus to={{ pathname: data.productListPage.redirectUrl, search: location.search } as Location} statusCode={301} />
      );
    else return <RedirectWithStatus to={process.env.REACT_APP_ERROR_404_ENDPOINT} statusCode={404} />;
  } else if (data && (history.location.state as any)?.prevPath !== location.pathname) {
    clearDigitalData();
  }

  return (
    <ProductListPage
      data={data.productListPage}
      contextInfo={props.contextInfo}
      plpService={plpService}
      pricingService={pricingService}
      basketService={basketService}
      productService={productService}
      partsListService={partsListService}
      locationHash={location.hash}></ProductListPage>
  );
};

export default PLPWrapper;
