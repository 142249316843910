import React, { FunctionComponent, useRef, useState } from 'react';
import { Button, Text, TextRef, isMobileDevice } from 'rs-emd-ui-atoms';
import { getLabel } from '../../../helpers/html.utils';
import { ILabel } from '../../../models/common/label.model';
import { IPredictiveSearchService } from '../../../services/predictive-search/predictive-search-service';
import { Search } from '../../header/search/search-component';
import styles from './search-field-component.module.scss';

type SearchFieldProps = {
  predictiveSearchService: IPredictiveSearchService;
  isPredictiveSearchEnabled: boolean;
  placeholder: string;
  disabledAutofocus?: boolean;
  familyId?: number;
  trackingData?: any;
  labels?: ILabel[];
  onSearchChange?: (value: string) => void;
};

export const SearchField: FunctionComponent<SearchFieldProps> = (props: SearchFieldProps) => {
  const [searchState, setSearchState] = useState({
    isFocused: false,
    value: '',
    isClearVisible: false,
  });
  const textInput = useRef<TextRef>(null);

  return (
    <div className={`${styles['search-container']} ${searchState.isFocused ? styles['search-container-focused'] : ''}`}>
      <Text
        autoFocus={props.disabledAutofocus === undefined ? true : !props.disabledAutofocus}
        icon={!searchState.value ? 'search' : undefined}
        placeholder={props.placeholder}
        className={`${styles['search-bar']}  ${searchState.isClearVisible || searchState.value ? styles['search-btns-visible'] : ''}`}
        value={searchState.value}
        ref={textInput}
        background='white'
        onChange={onSearchChange}
        onKeyUp={(e) => onSearchKeyUp(e)}
        onClick={() => updateSearchFocus(true)}
        onKeyDown={() => updateSearchFocus(true)}
        autoComplete='off'></Text>
      {/* Search Modal */}
      {props.isPredictiveSearchEnabled && searchState.isFocused && !isMobileDevice() && (
        <Search
          searchString={searchState.value}
          isClearClicked={searchState.value === ''}
          toggleSearch={() => updateSearchFocus(false, undefined, true)}
          labels={props.labels}
          predictiveSearchService={props.predictiveSearchService}
          resetSearch={onClearClick}
        />
      )}
      {/** Search Buttons */}
      <div id='search-btns' className={`${searchState.isClearVisible || searchState.value ? styles['search-btns'] : ''} `}>
        <Button
          text={getLabel(props.labels, 'clear')}
          buttonType='text'
          className={`${styles['clear-btn']} ${!searchState.isClearVisible ? 'hide' : ''}`}
          onClick={() => onClearClick()}
        />
        <Button
          className={`${styles['search-button']} ${!searchState.value ? 'hide' : ''}`}
          icon='search'
          buttonType='primary'
          onClick={search}
        />
      </div>
    </div>
  );

  function onSearchKeyUp(e: React.KeyboardEvent) {
    if (e.key === 'Enter') search();
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchState({
      ...searchState,
      value: e.currentTarget.value,
      isClearVisible: e.currentTarget.value.length > 3,
    });

    props.onSearchChange?.(e.currentTarget.value);
  }

  function search() {
    props.predictiveSearchService.search({
      variables: { searchQuery: searchState.value, familyId: props.familyId, trackingResult: props.trackingData },
    });
  }

  function updateSearchFocus(force?: boolean, state?: any, clear?: boolean) {
    if (force !== undefined) {
      if (!force) textInput.current?.blur();
      else textInput.current?.focus();
    }

    const clearState = clear ? { value: '', isClearVisible: false } : {};
    const currState = state || searchState;
    setSearchState({
      ...currState,
      isFocused: force ?? !searchState.isFocused,
      ...clearState,
    });
  }

  function onClearClick() {
    const tempState = {
      ...searchState,
      value: '',
      isClearVisible: false,
    };

    setSearchState(tempState);
    props.onSearchChange?.('');

    //to re-set focus to text box and ensure search modal stays open
    updateSearchFocus(true, tempState);
  }
};
