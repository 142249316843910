import { Subject } from 'rxjs';
import {
  BasketTotalDocument,
  BasketTotalQuery,
  BasketTotalQueryVariables,
  HeaderTotalsDocument,
  HeaderTotalsQuery,
  HeaderTotalsQueryVariables,
  PartsListsTotalDocument,
  PartsListsTotalQuery,
  PartsListsTotalQueryVariables,
} from '../generated/graphql';
import { IHeaderMenuRequest, IHeaderMenuResponse } from '../rs-emd-ui-modules/src/components/header/header-menu/header.dto';
import { IHeaderService } from './../rs-emd-ui-modules/src/services/header/header-service';
import { graphQLService } from './graphql-service';

export class HeaderService implements IHeaderService {
  constructor(readonly requestSubject = new Subject<IHeaderMenuRequest>(), readonly responseSubject = new Subject<IHeaderMenuResponse>()) {
    this.watchRequestSubject();
  }

  watchRequestSubject() {
    this.requestSubject.subscribe((s) => {
      switch (s.type) {
        case 'getBasketTotal':
          this.getBasketTotal();
          return;
        case 'getPartsListsTotal':
          this.getPartsListsTotal();
          return;
        case 'getHeaderTotals':
          this.getHeaderTotals();
          return;
      }
    });
  }

  // refresh basket totals
  getBasketTotal() {
    graphQLService.apolloClient
      .query<BasketTotalQuery, BasketTotalQueryVariables>({
        query: BasketTotalDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        result &&
          result.data &&
          this.responseSubject.next({
            type: 'getBasketTotal',
            basketTotal: result.data.header.basketTotal,
            noOfItemsInBasket: result.data.header.noOfItemsInBasket,
          });

        (window as any).ReactNativeWebView?.postMessage(result.data.header.noOfItemsInBasket);
      });
  }

  getPartsListsTotal() {
    graphQLService.apolloClient
      .query<PartsListsTotalQuery, PartsListsTotalQueryVariables>({
        query: PartsListsTotalDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        result &&
          result.data &&
          this.responseSubject.next({
            type: 'getPartsListsTotal',
            noOfPartsLists: result.data.header.noOfPartsLists,
          });
      });
  }

  // refresh all header data - customer, basket & partlist totals
  getHeaderTotals() {
    graphQLService.apolloClient
      .query<HeaderTotalsQuery, HeaderTotalsQueryVariables>({
        query: HeaderTotalsDocument,
        fetchPolicy: 'no-cache', // no need to cache
      })
      .then((result) => {
        result &&
          result.data &&
          this.responseSubject.next({
            type: 'getHeaderTotals',
            customer: result.data.header.customer,
            basketTotal: result.data.header.basketTotal,
            noOfItemsInBasket: result.data.header.noOfItemsInBasket,
            noOfPartsLists: result.data.header.noOfPartsLists,
          });
      });
  }
}

export const headerService = new HeaderService();
