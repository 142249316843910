import loadable from '@loadable/component';

const Basket = loadable(() => import(/* webpackChunkName: "basket" */ './basket/basket-component'), {
  resolveComponent: (components) => components.Basket,
});

const BasketHeader = loadable(() => import(/* webpackChunkName: "basket" */ './basket-header/basket-header-component'), {
  resolveComponent: (components) => components.BasketHeader,
});

const BasketContent = loadable(() => import(/* webpackChunkName: "basket" */ './basket-content/basket-content-component'), {
  resolveComponent: (components) => components.BasketContent,
});

const DeliveryModal = loadable(() => import(/* webpackChunkName: "basket" */ './delivery-modal/delivery-modal-component'), {
  resolveComponent: (components) => components.DeliveryModal,
});

const DeliveryOptions = loadable(() => import(/* webpackChunkName: "basket" */ './delivery-options/delivery-options-component'), {
  resolveComponent: (components) => components.DeliveryOptions,
});

const EmptyBasket = loadable(() => import(/* webpackChunkName: "basket" */ './empty-basket/empty-basket-component'), {
  resolveComponent: (components) => components.EmptyBasket,
});

const OrderHelp = loadable(() => import(/* webpackChunkName: "basket" */ './order-help/order-help-component'), {
  resolveComponent: (components) => components.OrderHelp,
});

const PrintAsQuote = loadable(() => import(/* webpackChunkName: "print-as-quote" */ './print-as-quote/print-as-quote-component'), {
  resolveComponent: (components) => components.PrintAsQuote,
});

const PPKModal = loadable(() => import(/* webpackChunkName: "basket" */ './ppk-modal/ppk-modal-component'), {
  resolveComponent: (components) => components.PpkModal,
});

const ProductArea = loadable(() => import(/* webpackChunkName: "basket" */ './product-area/product-area-component'), {
  resolveComponent: (components) => components.ProductArea,
});

const QuickOrder = loadable(() => import(/* webpackChunkName: "quick-order" */ './quick-order/quick-order-component'), {
  resolveComponent: (components) => components.QuickOrder,
});

const ShareBasketModal = loadable(() => import(/* webpackChunkName: "basket" */ './share-basket-modal/share-basket-modal-component'), {
  resolveComponent: (components) => components.ShareBasketModal,
});

const BulkAddLoader = loadable(
  () => import(/* webpackChunkName: "quick-order" */ './quick-order/bulk-add-loader/bulk-add-loader-component'),
  {
    resolveComponent: (components) => components.BulkAddLoader,
  }
);

const BulkAddResultModal = loadable(
  () => import(/* webpackChunkName: "quick-order" */ './quick-order/bulk-add-result-modal/bulk-add-result-modal-component'),
  {
    resolveComponent: (components) => components.BulkAddResultModal,
  }
);

export {
  Basket,
  BasketContent,
  BasketHeader,
  BulkAddLoader,
  BulkAddResultModal,
  DeliveryModal,
  DeliveryOptions,
  EmptyBasket,
  OrderHelp,
  PPKModal,
  PrintAsQuote,
  ProductArea,
  QuickOrder,
  ShareBasketModal,
};
