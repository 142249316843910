import React, { FunctionComponent, useContext, useEffect, useLayoutEffect, useState } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-consent-module';
import { useLocation } from 'react-router-dom';
import { Icon } from 'rs-emd-ui-atoms';
import { useMasterQuery } from '../../generated/graphql';
import { Footer, SiteWideBanner, getCookie } from '../../rs-emd-ui-modules/src';
import { Header } from '../../rs-emd-ui-modules/src/components/header/header-component.module';
import { MasterBodyEnd, MasterHeader } from '../../rs-emd-ui-modules/src/components/shared';
import { RedirectWithStatus } from '../../rs-emd-ui-modules/src/helpers/redirect-with-status.component';
import {
  allowAdvertisingCookies,
  allowAnalyticsCookies,
  allowFuntionalityCookies,
  getPreviewPricesCookie,
  privacyModalViewed,
  setCurrencyCookie,
  setPreviewPricesCookie,
} from '../../rs-emd-ui-modules/src/helpers/user-settings.utils';
import { IContextInfo } from '../../rs-emd-ui-modules/src/models/master/context-info.model';
import { graphQLService } from '../../services/graphql-service';
import { headerService } from '../../services/header-service';
import { menuService } from '../../services/menu-service';
import { partsListService } from '../../services/parts-list-service';
import { predictiveSearchService } from '../../services/predictive-search-service';
import { productService } from '../../services/product-service';
import { clickEvent, enableEnsighten } from '../../tagging/ensighten';
import { gtag, pushGA4Event } from '../../tagging/ga4';
import { Router } from '../router/router-component';
import './master-component.scss';
import { BulkAddResultModal, QuickOrder } from '../../rs-emd-ui-modules/src/components/basket-purchase/basket';
import { QuickOrderContext } from '../app/quick-order-context';
import { PartLists } from '../../rs-emd-ui-modules/src/components/parts-list';
import { PartsListContext } from '../app/parts-list-context';
import { IBulkAddToBasketResponse } from '../../rs-emd-ui-modules/src/models/basket/bulk-add-to-basket.model';

type MasterProps = {
  contextInfo: IContextInfo;
};

export const Master: FunctionComponent<MasterProps> = (props: MasterProps) => {
  // #68249
  const location = useLocation();

  const { loading, error, data } = useMasterQuery({ ssr: true });
  const [isCheckout, setIsCheckout] = useState(false);
  const [isWebview, setIsWebview] = useState(true);
  const [bulkAddToBasketResponse, setBulkAddToBasketResponse] = useState<IBulkAddToBasketResponse | undefined>(undefined);
  const [previewingPrices, setPreviewingPrices] = useState<string | undefined>();
  const { partsListProps, setPartsListProps } = useContext(PartsListContext);

  const { quickOrderProps, setQuickOrderVisible } = useContext(QuickOrderContext);
  const { closeCallback, basketUpdateCallback } = quickOrderProps ?? {};

  useEffect(() => {
    // webview set in cookie?
    setIsWebview(Boolean(JSON.parse(getCookie('webview') ?? '0'))); // will hide the header
  }, []);

  // when url changes
  useLayoutEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    // currency
    const changecurrency = searchParams.get('changecurrency');
    if (changecurrency) setCurrencyCookie(undefined, changecurrency);

    // purchase
    setIsCheckout(location.pathname === '/purchase'); // will trigger basic header/footer

    // webview
    setIsWebview(Boolean(JSON.parse(searchParams.get('webview') ?? getCookie('webview') ?? '0'))); // will hide the header

    // EMD-1263
    const previewPrices = searchParams.get('previewPrices');
    if (previewPrices) setPreviewPricesCookie(previewPrices);
    setPreviewingPrices(previewPrices ?? getPreviewPricesCookie());
  }, [location, location.pathname, location.search]);

  useLayoutEffect(() => {
    if (data) {
      // configure bot details
      graphQLService.isBot = props.contextInfo.isBot;

      // Configure Google Tag Manager (GTM)
      if (data.master.tagging.gtm.isEnabled) {
        const tagManagerArgs: TagManagerArgs = {
          gtmId: data.master.tagging.gtm.gtmid,
          consentSettings: getConsetSettings(),
        };

        TagManager.initialize(tagManagerArgs);
      }
    }
  }, [data, props.contextInfo.isBot]);

  useLayoutEffect(() => {
    var interval: NodeJS.Timeout;

    if (data?.master.tagging.gtm.isEnabled) {
      // if privacy modal not yet shown to the client, check every 1s
      if (!privacyModalViewed()) {
        interval = setInterval(() => {
          if (privacyModalViewed()) {
            // update consent
            gtag('consent', 'update', getConsetSettings());

            clearInterval(interval);
          }
        }, 1000);
      }
    }

    return () => clearInterval(interval);
  }, [data]);

  if (loading) {
    return <div>Loading...</div>;
  } else if (error || !data) {
    return <RedirectWithStatus to={process.env.REACT_APP_ERROR_ENDPOINT} statusCode={500} />;
  }

  return (
    <>
      {previewingPrices && (
        <div className={`notification-bar`}>
          <p>Currently viewing Prices as at {previewingPrices}</p> <Icon name='close' className='close' onClick={onCloseClick}></Icon>
        </div>
      )}
      <MasterHeader data={data.master} enableEnsighten={enableEnsighten} />

      <div id={`pageWrapper`} className={`page-wrapper`}>
        {!isWebview && (
          <Header
            data={data}
            contextInfo={props.contextInfo}
            menuService={menuService}
            headerService={headerService}
            predictiveSearchService={predictiveSearchService}
            partsListService={partsListService}
            productService={productService}
            isCheckout={isCheckout}
            clickEvent={clickEvent}></Header>
        )}

        {/** Site-Wide Banner */}
        {data.master.config.enableSiteWideBanner && !isCheckout && <SiteWideBanner labels={data.master.labels} />}

        <div className={`page-content-wrapper ${data.master.config.enableSiteWideBanner ? 'page-content-wrapper-with-banner' : ''}`}>
          <div className='content'>
            <Router contextInfo={props.contextInfo} enableMyAccount={data.master.config.enableMyAccount}></Router>
          </div>
        </div>
      </div>

      {quickOrderProps?.isVisible && (
        <QuickOrder
          {...quickOrderProps}
          closeCallback={() => {
            setQuickOrderVisible(false);
            closeCallback && closeCallback();
          }}
          labels={data.master.labels ?? []}
          basketUpdateCallback={(response) => {
            setBulkAddToBasketResponse(response);
            setQuickOrderVisible(false);
            basketUpdateCallback && basketUpdateCallback();
          }}
        />
      )}

      {bulkAddToBasketResponse && (
        <BulkAddResultModal
          labels={data.master.labels ?? []}
          bulkAddToBasketResult={bulkAddToBasketResponse}
          closeCallback={() => setBulkAddToBasketResponse(undefined)}
          addMoreProductsCallback={() => {
            setQuickOrderVisible(true);
            setBulkAddToBasketResponse(undefined);
          }}
          viewBasketCallback={() => {
            setQuickOrderVisible(false);
            if (location.pathname !== '/cart') window.location.href = '/cart';
          }}></BulkAddResultModal>
      )}

      {partsListProps && (
        <PartLists
          closeCallback={() => {
            setPartsListProps(undefined);
          }}
          labels={data.master.labels}
          {...partsListProps}
        />
      )}

      {!isWebview && <Footer data={data.footer} isCheckout={isCheckout} pushGA4Event={pushGA4Event} />}

      {/* Body End Scripts */}
      <MasterBodyEnd bodyEnd={data.master.scripts.bodyEnd}></MasterBodyEnd>
    </>
  );

  function getConsetSettings(): {
    ad_storage?: 'denied' | 'granted';
    analytics_storage?: 'denied' | 'granted';
    functional_storage?: 'denied' | 'granted';
  } {
    const allowAdvertising = allowAdvertisingCookies();
    const allowAnalytics = allowAnalyticsCookies();
    const allowFuntionality = allowFuntionalityCookies();

    // if !privacyModalViewed, assume undefined means permission granted - during initialization,
    // otherwise, permission cookie must be 'true' to be granted
    const acceptedValue = privacyModalViewed() ? true : undefined;

    return {
      ad_storage: allowAdvertising === acceptedValue || allowAdvertising ? 'granted' : 'denied',
      analytics_storage: allowAnalytics === acceptedValue || allowAnalytics ? 'granted' : 'denied',
      functional_storage: allowFuntionality === acceptedValue || allowFuntionality ? 'granted' : 'denied',
    };
  }

  function onCloseClick(): void {
    // remove cookie val & query param
    setPreviewPricesCookie(undefined);
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('previewPrices');
    const newQS = searchParams.toString();
    window.location.href = `${location.pathname}${newQS ? '?' + newQS : ''}`;
  }
};

export default Master;
