import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  OktaTokenExchangeDocument,
  OktaTokenExchangeMutation,
  OktaTokenExchangeMutationVariables,
} from '../generated/graphql';
import { ILoginService } from '../rs-emd-ui-modules/src/services/profile/login/login-service';
import {
  ILoginRequest,
  ILoginResponse,
  IOktaTokenExchangeRequest,
  IOktaTokenExchangeResponse,
} from '../rs-emd-ui-modules/src/services/profile/login/login.dto';
import { pushEvent } from '../tagging/ensighten';
import { pushGA4Event } from '../tagging/ga4';
import { graphQLService } from './graphql-service';
import { headerService } from './header-service';

export class LoginService implements ILoginService {
  pushEvent(): void {
    return pushEvent('loginOrContinueAsGuestEvent', undefined);
  }

  async login(req: ILoginRequest): Promise<ILoginResponse> {
    const result = await graphQLService.apolloClient.mutate<LoginMutation, LoginMutationVariables>({
      mutation: LoginDocument,
      variables: { data: req },
    });

    let res: ILoginResponse = {};

    if (result.data) {
      res.data = result.data.login;

      if (res.data.isSuccess) {
        //update header totals
        headerService.requestSubject?.next({ type: 'getHeaderTotals' });

        pushEvent('login.successful', '');

        result.data.login.tags.forEach((t) => {
          if (t.__typename === 'GA4Event_User_Login') {
            pushGA4Event(t);
          }
        });
      } else {
        pushEvent('login.error', 'bad_credentials');
      }
    }

    return res;
  }

  async oktaTokenExchange(req: IOktaTokenExchangeRequest): Promise<IOktaTokenExchangeResponse> {
    const result = await graphQLService.apolloClient.mutate<OktaTokenExchangeMutation, OktaTokenExchangeMutationVariables>({
      mutation: OktaTokenExchangeDocument,
      variables: { data: req },
    });

    let res: IOktaTokenExchangeResponse = {};

    if (result.data) {
      res.data = result.data.oktaTokenExchange;

      if (res.data.isSuccess) {
        //update header totals
        headerService.requestSubject?.next({ type: 'getHeaderTotals' });

        pushEvent('login.successful', '');
      } else {
        pushEvent('login.error', 'bad_credentials');
      }
    }

    return res;
  }
}

export const loginService = new LoginService();
